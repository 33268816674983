import React from 'react';
import NoData from '../NoData';

const Tbody = ({ column, data, checkbox, checkboxArray, checkboxCallback }) => {
    let columnCount = column.length;
    if(checkbox) columnCount += 1;

    const handleCheckbox = (id) => {
        let index = checkboxArray.indexOf(id);
        return index> -1 ? true : false;
    }

    return (<tbody>
        {
            data && data!="" ? (
                data.map((item, k) => {
                    return (
                        <tr key={k}>
                            { checkbox && (
                                <td style={{minWidth: '47.2px', width: '47.2px'}}>
                                    <input type="checkbox" checked={handleCheckbox(k)} onChange={(e) => checkboxCallback({toggle: e.target.checked, key: k})} />
                                </td>
                            )}
                            { column.map((field, key) => {
                                const dataValue = item[field['key']];

                                if(field['view']) {
                                    return (<td key={key}>{field.view(item)}</td>);
                                }

                                if(dataValue) {
                                    return (<td key={key}>{dataValue}</td>);
                                }

                                return (<td key={key}></td>);
                            }) }
                        </tr>
                    )
                })
            ) : (<tr>
                <td colSpan={columnCount}><NoData /></td>
            </tr>)
        }
    </tbody>);
}

export default Tbody;