import React from 'react';

const FormGroup = (props) => {
    const { label, required, children } = props;

    return (
        <div className="form-group">
            <div className="label-group">
                <label>{ label }</label>
                { required && <div className="text-secondary font-size-sm">( 必填項目 )</div> }
            </div>
            { children }
        </div>
    )
}

const InputGroup = (props) => {
    const { desc, children } = props;

    return(
        <div className="input-group">
            { children }
            { desc && <div className="input-desc">{ desc }</div> }
        </div>
    )
}

export { FormGroup, InputGroup };