import React, { useState } from 'react';

const SearchBar = (props) => {
    const { searchCallback, searchbar } = props;
    const [ keyword, setKeyword ] = useState('');
    const [ searchDate, setSearchDate ] = useState({start: '', end: ''});
    const [ searchBarExpand, setSearchBarExpand ] = useState(false);

    const inputClear = (e) => {
        setKeyword('');
        e.target.previousSibling.value = "";
    }

    const handleDateChange = (key, value) => {
        if(key === 1){
            setSearchDate({...searchDate, start: value});
        }else if(key === 2){
            setSearchDate({...searchDate, end: value});
        }
    }

    const inputDateClear = (key, e) => {
        e.target.previousSibling.value = "";
        if(key === 1){
            setSearchDate({...searchDate, start: ""});
        }else if(key === 2){
            setSearchDate({...searchDate, end: ""});
        }
    }

    const handleKeyword = (e) => {
        let searchBtn = document.getElementById('search-btn');
        setKeyword(e.target.value);
        if(e.keyCode === 13){
            searchBtn.click();
        }
    }

    const handleExpand = () => {
        let windowWidth = window.innerWidth;
        if(windowWidth <= 415){
            setSearchBarExpand(!searchBarExpand);
        }
    }

    return (
        <div className={`search-bar ${searchBarExpand ? 'active' : ''}`}>
            <h6 className={`mg-0 mr-2 my-1 py-2 font-size-lg font-weight-bold flex-fill ${searchBarExpand ? 'active' : ''}`} onClick={() => handleExpand()}>搜尋選單</h6>
            <div className="d-flex align-items-center justify-content-end flex-wrap flex-fill">
                {
                    searchbar.date && (<>
                        <div className="search-input-group">
                            <input data-text={searchDate.start ? '' : '請選擇起始日'} type="date" name="start_date" className="inputDate" onChange={(e) => handleDateChange(1, e.target.value)} />
                            <div className={`search-input-cross date ${searchDate.start === "" ? 'd-none' : 'd-block'}`} onClick={(e) => inputDateClear(1, e)} ></div>
                        </div>
                        <span className="mx-1">～</span> 
                        <div className="search-input-group">
                            <input data-text={searchDate.end ? '' : '請選擇結束日'} type="date" name="end_date" className="inputDate" onChange={(e) => handleDateChange(2, e.target.value)} />
                            <div className={`search-input-cross date ${searchDate.end === "" ? 'd-none' : 'd-block'}`} onClick={(e) => inputDateClear(2, e)}></div>
                        </div>
                    </>)
                }
                {
                    searchbar.keyword && (
                        <div className="search-input-group keyword">
                            <input type="text" className="searchInput" placeholder="請輸入關鍵字" onKeyUp={(e) => handleKeyword(e)} />
                            <div className={`search-input-cross ${keyword === "" ? 'd-none' : 'd-block'}`} onClick={(e) => inputClear(e)}></div>
                        </div>
                    )
                }
                <button className="search-btn" id="search-btn" onClick={() => searchCallback({keyword: keyword, date: searchDate})}></button>
            </div>
        </div>
    )
}

export default SearchBar;