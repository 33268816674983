import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import Editor from '../../../components/Editor';

export default function Create() {
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data, setData ] = useState("<p>使用指南內容</p>");

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" style={{maxWidth: "unset"}} onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="使用指南" required>
                        <Editor data={data} callback={(data) => setData(data)} />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}