import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { useParams } from 'react-router-dom';
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import ConfirmBox from "../../../components/ConfirmBox";
import { getUserItem, updateUser } from "../../../services/authService";

export default function Create(props) {
    let mounted = true;
    const { id } = useParams();
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ data, setData ] = useState({});

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getUserItem(id);
        if(mounted) {
            setData(result.data);
        }
        setLoader(false);
    }, [])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let data = getValues();

        setLoader(true);
        updateUser(id, data).then(res => {
            setSnack({open: true, text: res.messages});
            document.getElementById("password").value = "";
            document.getElementById("password_confirmation").value = "";
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入真實姓名"});
            return;
        }
        if(values.phone === ""){
            setSnack({open: true, text: "請輸入電話號碼"});
            return;
        }
        if(values.password !== "" && values.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return;
        }
        if(values.password_confirmation !== "" && values.password === ""){
            setSnack({open: true, text: "請輸入登入密碼"});
            return;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return;
            }
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="真實姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入真實姓名"
                                defaultValue={data.name}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="電話號碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="phone"
                                type="tel"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入電話號碼"
                                defaultValue={data.phone}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="登入密碼">
                        <InputGroup>
                            <input
                                id="password"
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼">
                        <InputGroup>
                            <input
                                id="password_confirmation"
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="new-password"
                                placeholder="若無需更改請留空"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                    {repasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
            </form>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定修改嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}