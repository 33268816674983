import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function Prize(props) {
    const { setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ open, setOpen ] = useState(false);

    const fakeData = {
        active_page: 1,
        countPerPage: 10,
        total_rows: 1,
        data: [
            {
                id: 1,
                type: "一般",
                title: "title1",
                content: "content1",
                status: "1",
                top: "1",
                created_at: "2021/06/08 15:10:31",
            },
            {
                id: 2,
                type: "活動",
                title: "title2",
                content: "content2",
                status: "0",
                top: "1",
                created_at: "2021/06/07 15:10:31",
            },
            {
                id: 3,
                type: "系統",
                title: "title3",
                content: "content3",
                status: "1",
                top: "0",
                created_at: "2021/06/06 15:10:31",
            },
            {
                id: 4,
                type: "其它",
                title: "title4",
                content: "content4",
                status: "0",
                top: "0",
                created_at: "2021/06/05 15:10:31",
            },
        ]
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "狀態",
            view: (data) => {
                return data.status === "1" ? <span className="text-success">上架</span> : <span className="text-danger">下架</span> ;
            }
        },
        {
            key: 'top',
            name: "置頂",
            view: (data) => {
                return data.top === "1" ? <span className="text-success">是</span> : <span className="text-danger">否</span> ;
            }
        },
        {
            key: 'type',
            name: "分類",
            view: (data) => {
                return (
                    <span
                        className="font-weight-bold font-size-md"
                        style={{
                            borderRadius: '30px',
                            backgroundColor: 'rgba(0,0,0,0.05)',
                            padding: "3px 12px"
                        }}
                    >
                        {data.type}
                    </span>
                )
            }
        },
        {
            key: 'title',
            name: "標題",
        },
        {
            key: 'created_at',
            name: "發佈日期",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/application/News/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改公告</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await getAdminList(callbackParams ? callbackParams : params);
        let reuslt = await fakeData;
        setData(reuslt);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to={`/application/News/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增公告'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-bullhorn ml-1 mr-2"></i>公告列表</>}
                checkbox={false}
                maxWidth="992px"
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}