import React , { useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { Collapse } from 'reactstrap';
import navigation from '../_nav';

export default function Menu() {
    const [menu, setMenu] = useState(navigation);

    const handleChangeMenu = (key, toggle) => {
        let menus = menu;
        let item = menus[key];
        menus[key] = {...item, menuOpen: toggle};
        setMenu(menus);
    }

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <div className="sidebar">
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {  menu.map((item, key) => <MenuList item={item} key={key} callback={(e) => handleChangeMenu(key, e)} />) }
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

const MenuList = ({ item, callback }) => {
    let location = useLocation();
    let pathname = location.pathname.split('/');
    pathname.shift();
    
    const [ toggle, setToggle ] = useState(item.menuOpen);

    const handleToggle = () => {
        let result = !toggle;
        setToggle(result);
        callback(result);
    }

    return (
        <li className={`nav-item ${item.children ? 'has-treeview' : ''} ${toggle ? 'menu-open' : ''} ${item.close ? 'd-none' : ''} `} >
            {
                item.children ? (
                    <div
                        className={`nav-link ${pathname.find(path => '/'+path === item.url) ? 'active' : ''}`}
                        onClick={() => handleToggle()}
                    >
                        {item.icon ? <i className={`${item.icon} mr-2`}/> : <i className={`nav-icon`} />}
                        <p>{ item.name } { item.children&&(<i className="right fal fa-angle-left fa-lg" />) }</p>
                    </div>
                ) : (
                    <ButtonBase className="w-100 mb-1">
                        <Link
                            to={!item.children ? item.url : '#'}
                            className={`nav-link ${pathname.find(path => '/'+path === item.url) ? 'active' : ''}`}
                            onClick={() => handleToggle()}
                        >
                            {item.icon ? <i className={`${item.icon} mr-2`}/> : <i className={`nav-icon`} />}
                            <p>{ item.name } { item.children&&(<i className="right fal fa-angle-left fa-lg" />) }</p>
                        </Link>
                    </ButtonBase>
                )
            }
            { item.children && (
                <Collapse isOpen={toggle}>
                    <ul className="nav nav-treeview">
                    { item.children.map((children, key) => (
                        <li className="nav-item" key={key}>
                            <ButtonBase className="w-100 mb-1">
                                <NavLink to={item.url + children.url} className="nav-link" activeClassName="active">
                                    <i className={`${children.icon} mr-2`}/>{children.name}
                                </NavLink>
                            </ButtonBase>
                        </li>
                    ))}
                    </ul>
                </Collapse>
            )}
        </li>
    )
}