import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { useParams } from 'react-router-dom';
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import ConfirmBox from "../../../components/ConfirmBox";
import Upload from '../../../components/Upload';
import { getUserIDCard, updateUserIDCard } from "../../../services/authService";

export default function Create(props) {
    let mounted = true;
    const { id } = useParams();
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ data, setData ] = useState({
        image:{
            image_front: "",
            image_back: "",
        }
    });

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getUserIDCard(id);
        if(mounted) {
            setData(result.data);
        }
        setLoader(false);
    }, [])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();

        if(data.image && data.image.image_front !=="" && values.image_front.length === 0) {
            delete values.image_front;
        }

        if(data.image && data.image.image_back !=="" && values.image_back.length === 0) {
            delete values.image_back;
        }

        const formData = new FormData();
		Object.keys(values).map(function(key, index) {
            if(key === 'image_front') return formData.append(key, values.image_front[0]);
            if(key === 'image_back') return formData.append(key, values.image_back[0]);
			return formData.append(key, values[key]);
        });

        setLoader(true);
        updateUserIDCard(id, formData).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入身份證姓名"});
            return;
        }
        if(values.number === ""){
            setSnack({open: true, text: "請輸入身份證字號"});
            return;
        }
        if(values.image_front.length === 0 && data.image.image_front === ""){
            setSnack({open: true, text: "請上傳圖片 (身份證正面)"});
            return;
        }
        if(values.image_back.length === 0 && data.image.image_back === ""){
            setSnack({open: true, text: "請上傳圖片 (身份證反面)"});
            return;
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="會員帳號">
                        <div style={{height: "42.8px"}} className="d-flex align-items-center">{data.phone}</div>
                    </FormGroup>
                    <FormGroup label="會員姓名">
                        <div style={{height: "42.8px"}} className="d-flex align-items-center">{data.user_name}</div>
                    </FormGroup>
                    <FormGroup label="驗證類別" required>
                        <select
                            name="type"
                            defaultValue={data.type}
                            ref={register}
                        >
                            <option value="idcard">身份證</option>
                        </select>
                    </FormGroup>
                    <FormGroup label="身份證姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入身份證姓名"
                                defaultValue={data.name}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="身份證字號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="number"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入身份證字號"
                                defaultValue={data.number}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="身份證正面">
                        <Upload
                            id="image_front"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image_front"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image_front", "")}
                            defaultValue={data.image && data.image.image_front && data.image.image_front}
                        />
                    </FormGroup>
                    <FormGroup label="身份證反面">
                        <Upload
                            id="image_back"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image_back"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image_back", "")}
                            defaultValue={data.image && data.image.image_back && data.image.image_back}
                        />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
            </form>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定修改嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}