const navigation = [
    // {
    //     name: '儀表板',
    //     url: '/dashboard',
    //     icon: 'fal fa-chart-line',
    //     main: true,
    // },
    // {
    //     name: '系統管理',
    //     url: '/system',
    //     icon: '',
    //     menuOpen: true,
    //     children: [
    //         {
    //             name: '管理員列表',
    //             url: '/Administrator',
    //             icon: 'fas fa-user-shield',
    //             main: true,
    //             children: [
    //                 {
    //                     name: '新增管理員',
    //                     url: '/create',
    //                 },
    //                 {
    //                     name: '編輯管理員',
    //                     url: '/edit',
    //                 },
    //             ]
    //         },
    //     ],
    // },
    {
        name: '會員管理',
        url: '/member',
        icon: '',
        menuOpen: true,
        children: [
            {
                name: '會員列表',
                url: '/List',
                icon: 'fas fa-users',
                main: true,
                children: [
                    {
                        name: '編輯會員資料',
                        url: '/edit',
                    },
                    {
                        name: '好友列表',
                        url: '/team',
                    },
                    {
                        name: '新增會員',
                        url: '/create',
                    },
                ]
            },
            // {
            //     name: '信箱管理',
            //     url: '/MailAuth',
            //     icon: 'fas fa-envelope',
            //     main: true,
            // },
            {
                name: '身份證管理',
                url: '/Idcard',
                icon: 'fas fa-id-card',
                main: true,
                children: [
                    {
                        name: '編輯身份證',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '銀行帳戶管理',
                url: '/BankPayment',
                icon: 'fas fa-money-check-edit',
                main: true,
                children: [
                    {
                        name: '編輯銀行帳戶',
                        url: '/edit',
                    },
                ]
            },
            // {
            //     name: '電子錢包管理',
            //     url: '/ElectronicWalletAuth',
            //     icon: 'fas fa-money-check-alt',
            //     main: true,
            // },
            {
                name: '合會檔案路徑管理',
                url: '/BidUrl',
                icon: 'fas fa-file-alt',
                main: true,
                children: [
                    {
                        name: '編輯合會檔案路徑',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '組織圖檔案路徑管理',
                url: '/OrgChartUrl',
                icon: 'fas fa-file-alt',
                main: true,
                children: [
                    {
                        name: '編輯組織圖檔案路徑',
                        url: '/edit',
                    },
                ]
            },
            {
                name: '觀光點數檔案路徑管理',
                url: '/TravelPointUrl',
                icon: 'fas fa-file-alt',
                main: true,
                children: [
                    {
                        name: '編輯觀光點數檔案路徑',
                        url: '/edit',
                    },
                ]
            },
        ],
    },
    // {
    //     name: '財務管理',
    //     url: '/finance',
    //     icon: '',
    //     menuOpen: true,
    //     children: [
    //         {
    //             name: '會員資產管理',
    //             url: '/Assets',
    //             icon: 'fas fa-wallet',
    //             main: true,
    //         },
    //         {
    //             name: '點數 / 獎金發送',
    //             url: '/Transfer',
    //             icon: 'fas fa-paper-plane',
    //             main: true,
    //         },
    //         {
    //             name: '會員獎金紀錄',
    //             url: '/MemberBonus',
    //             icon: 'fas fa-coins',
    //             main: true,
    //         },
    //         {
    //             name: '獎金轉點紀錄',
    //             url: '/Exchange',
    //             icon: 'fas fa-exchange',
    //             main: true,
    //         },
    //     ],
    // },
    // {
    //     name: '標會管理',
    //     url: '/bid',
    //     icon: '',
    //     menuOpen: true,
    //     children: [
    //         {
    //             name: '標會列表',
    //             url: '/List',
    //             icon: 'fas fa-flag',
    //             main: true,
    //         },
    //         {
    //             name: '得標紀錄',
    //             url: '/SuccessfulBidder',
    //             icon: 'fas fa-trophy',
    //             main: true,
    //         },
    //         {
    //             name: '標會參數設定',
    //             url: '/Config',
    //             icon: 'fas fa-cog',
    //             main: true,
    //         },
    //     ],
    // },
    // {
    //     name: '應用管理',
    //     url: '/application',
    //     icon: '',
    //     menuOpen: true,
    //     children: [
    //         {
    //             name: '消息管理',
    //             url: '/News',
    //             icon: 'fas fa-bullhorn',
    //             main: true,
    //             children: [
    //                 {
    //                     name: '新增公告',
    //                     url: '/create',
    //                 },
    //                 {
    //                     name: '編輯公告',
    //                     url: '/edit',
    //                 },
    //             ]
    //         },
    //         {
    //             name: '推播通知管理',
    //             url: '/Notification',
    //             icon: 'fas fa-bell',
    //             main: true,
    //             children: [
    //                 {
    //                     name: '新增推播通知',
    //                     url: '/create',
    //                 },
    //                 {
    //                     name: '編輯推播通知',
    //                     url: '/edit',
    //                 },
    //             ]
    //         },
    //         {
    //             name: '廣告輪播管理',
    //             url: '/Banner',
    //             icon: 'fas fa-image',
    //             main: true,
    //             children: [
    //                 {
    //                     name: '新增廣告輪播',
    //                     url: '/create',
    //                 },
    //                 {
    //                     name: '編輯廣告輪播',
    //                     url: '/edit',
    //                 },
    //             ]
    //         },
    //         {
    //             name: '使用指南管理',
    //             url: '/UserGuide',
    //             icon: 'fas fa-compass',
    //             main: true,
    //         },
    //         {
    //             name: '制式標會合約內容',
    //             url: '/Contract',
    //             icon: 'fas fa-pen-alt',
    //             main: true,
    //         },
    //         {
    //             name: '關於我們',
    //             url: '/AboutUs',
    //             icon: 'fas fa-exclamation-circle',
    //             main: true,
    //         },
    //         {
    //             name: '公司帳戶',
    //             url: '/CompanyAccount',
    //             icon: 'fas fa-building',
    //             main: true,
    //         },
    //         {
    //             name: '簡訊管理',
    //             url: '/Sms',
    //             icon: 'fas fa-sms',
    //             main: true,
    //         },
    //     ],
    // },
]


export default navigation;
  