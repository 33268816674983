import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AdminMenu from './components/AdminMenu';
// import useWindowSize from '../../../components/useWindowSize';
import HamburgerMenu from '../../../components/HamburgerMenu';
import ButtonBase from '@material-ui/core/ButtonBase';

const Header = (props) => {
    // const resize = useWindowSize();
    const location = useLocation();
    const { user, title, setLoader, setSnack, menuToggle, menuCallback } = props;

    useEffect(() => {
        if(window.innerWidth < 992){
            menuCallback(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        if(menuToggle) {
            document.body.classList.remove('sidebar-collapse','sidebar-closed');
            document.body.classList.add('sidebar-open');
        }else{
            document.body.classList.add('sidebar-collapse','sidebar-closed');
            document.body.classList.remove('sidebar-open');
        }
    }, [menuToggle]);

    return (<>
        <header className="main-header navbar navbar-expand">
            <nav className="navbar-nav">
                <div className="d-flex align-items-center flex-fill h-100" style={{width: '1px'}}>
                    {/* <Link to="/dashboard" className="logo">
                        <img src={require('../../../../images/logo/logo_white2.png').default} alt="logo" style={{maxHeight: '50px'}} />
                    </Link> */}
                    {/* {
                        window.innerWidth < 415 ? (
                            <HamburgerMenu toggle={menuToggle} menuCallback={() => menuCallback(!menuToggle)} />
                        ) : (
                            <i className="hamburger-btn far fa-bars mx-2 pd-2" onClick={() => menuCallback(!menuToggle)}/>
                        )
                    } */}
                    <ButtonBase className="mx-1" style={{borderRadius: '50%'}}>
                        <HamburgerMenu toggle={menuToggle} menuCallback={() => menuCallback(!menuToggle)} />
                    </ButtonBase>
                    <span className="nav-title">{title}</span>
                </div>
                <ButtonBase className="h-100 mr-2">
                    <AdminMenu user={user} setLoader={setLoader} setSnack={setSnack} />
                </ButtonBase>
            </nav>
        </header>
    </>)
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.Auth.isAuthenticated,
		user: state.Auth.user
    }
};

export default connect(mapStateToProps)(Header);