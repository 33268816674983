import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

import { userOrgChartPathList } from '../../../services/authService';

export default function Page(props) {
    const { setSnack, setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ open, setOpen ] = useState(false);
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "會員帳號": item.phone,
                        "會員姓名": item.name,
                        "檔案路徑": item.url,
                        "備註": item.note,
                        "更新時間": moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'phone',
            name: "會員帳號",
        },
        {
            key: 'name',
            name: "會員姓名",
        },
        {
            key: 'url',
            name: "檔案路徑",
            view: (data) => {
                return data.url ? <a href={data.url} target="_blank" rel="noreferrer"><i className="fad fa-file-alt text-secondary" style={{fontSize: "32px"}}></i></a> : "-";
            }
        },
        {
            key: 'note',
            name: "備註",
            view: (data) => {
                return data.note ? data.note : '-';
            }
        },
        {
            key: 'updated_at',
            name: "更新時間",
            view: (data) => {
                return moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/member/OrgChartUrl/edit/${data.id}`} className="common-btn table-btn green"><i className="fas fa-upload mr-1"></i>上傳檔案</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await userOrgChartPathList(callbackParams ? callbackParams : params);
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <CSVLink filename="組織圖檔案路徑列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-file-alt ml-1 mr-2"></i>組織圖檔案路徑列表</>}
                checkbox={false}
                maxWidth="992px"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}