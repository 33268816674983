import React, { useEffect, useRef} from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

const LightBox = (props) => {
    const ModalPortal = window.document.getElementById('modal-root');

    const { open, callback, children, className, cross, width} = props;
    const modalRef = useRef(null);
    
    useEffect(() => {
        if(open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [open])

    const handleToggle = (e) => {
        if(!modalRef.current.contains(e.target)) {
            callback({open: false});
        }
    }

    const handleClose = () => {
        callback({open: false});
    }

    const modal = (
        <CSSTransition
            in={open}
            timeout={300}
            classNames="lightbox-transition"
            unmountOnExit
        >
            <div className={`modal-box ${className ? className : ''}`} onClick={handleToggle}>
                <div className="light-box" style={{maxWidth: width}} ref={modalRef}>
                    {children}
                    { cross && <i className="light-box-close" onClick={() => handleClose()}></i>}
                </div>
            </div>
        </CSSTransition>
    )

    return createPortal(modal, ModalPortal);
}

export default LightBox;