import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PublicRoute = ({component: Component, isAuthenticated, ...rest}) => {
    const { setLoader, setSnack } = rest;
    const initialProps = { setLoader, setSnack };

    return (
        <Route {...rest} render={props => (
            ! isAuthenticated ? (
                <Component {...props} {...initialProps} />
            ) : (
                <Redirect to={{
                    pathname: '/member/List',
                    state: {from: props.location}
                }}/>
            )
        )}/>
    );
}


const mapStateToProps = (state) => {
    return {
        isAuthenticated : state.Auth.isAuthenticated,
    }
};

export default connect(mapStateToProps)(PublicRoute);