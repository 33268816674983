import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

const ConfirmBox = (props) => {
    const ModalPortal = window.document.getElementById('modal-root');

    const { open, confirmText, callback, cancel, confirm } = props;
    const modalRef = useRef(null);

    useEffect(() => {
        if(open) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
    }, [open])

    const handleCancel = () => {
        callback({open: false, confirm: false});
    }

    const handleConfirm = () => {
        callback({open: false, confirm: true});
    }

    const handleToggle = (e) => {
        if(!modalRef.current.contains(e.target)) {
            callback({open: false, confirm: false});
        }
    }

    const modal = (
        <CSSTransition
            in={open}
            timeout={300}
            classNames="confirm-transition"
            unmountOnExit
        >
            <div className="modal-box" onClick={handleToggle}>
                <div className="confirm-box" ref={modalRef}>
                    <div className="confirm-box-header"></div>
                    <div className="confirm-box-body">{confirmText}</div>
                    <div className="confirm-box-footer">
                        { cancel.show && <button type={cancel.type} className="confirm-btn cancel" onClick={() => handleCancel()}>{ cancel.text ? cancel.text : "取消" }</button> }
                        { confirm.show && <button type={confirm.type} className="confirm-btn confirm" onClick={() => handleConfirm()}>{ confirm.text ? confirm.text : "繼續" }</button> }
                    </div>
                </div>
            </div>
        </CSSTransition>
    )

    return createPortal(modal, ModalPortal);
}

export default ConfirmBox;