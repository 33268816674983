import React, {useState, useEffect, useRef} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BackToTop from "./components/BackToTop";

const Content = (props) => {
    let history = useHistory();
    let location = useLocation();
    const { title, main, children, menuCallback } = props;
    const wrapperEl = useRef(null);
    const [ toggle, setToggle ] = useState(false);

    // console.log(main)

    useEffect(() => {
        // wrapperEl.current.onscroll = () => {
        //     if(wrapperEl.current.scrollTop > 400) {
        //         setToggle(true);
        //     }else{
        //         setToggle(false);
        //     }
        // }
        setTimeout(() => {
            wrapperEl.current.scroll({top: 0})
        }, 200)
    }, [location.pathname])

    const menuClose = () => {
        window.innerWidth < 992 && menuCallback(false);
    }

    return (
        <div
            className="content-wrapper"
            onClick={menuClose}
            ref={wrapperEl}
        >
            <div className="content-header">
                {!main && (
                    <div className="mr-3 mt-3" style={{cursor: 'pointer'}} onClick={() => history.goBack()}>
                        <i className="far fa-chevron-left pd-1"></i>
                        <span className="ml-1">返回</span>
                    </div>
                )}
                <ul className="d-flex justify-content-end align-items-center flex-fill mr-1 mt-3 breadcrumb float-sm-right">
                    {title.map((name, key) => <li className={`breadcrumb-item ${key !== 0 ? "active" : ""}`} key={key}>{name}</li>)}
                </ul>
            </div>
            
            { children }
            {/* <BackToTop toggle={toggle} callback={() => wrapperEl.current.scroll({top: 0, behavior: 'smooth'})} /> */}
        </div>
    );
}

export default Content;
