import Http from "../utils/Http";
import * as action from "../store/actions";

let BASE_API_URL
let API_URL;

if(process.env.REACT_APP_ENVIRONMENT === 'development') {
	BASE_API_URL = process.env.REACT_APP_TEST_BASE_API_URL;
	API_URL = process.env.REACT_APP_TEST_API_URL;
} else {
	BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
	API_URL = process.env.REACT_APP_API_URL;
}

export function login(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/auth/login", credentials)
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
				// console.clear();
	});
}

export function refreshToken() {
	let credentials = {
		grant_type: "refresh_token",
		refresh_token: localStorage.getItem('refresh_token')
	}
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", credentials)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function logout() {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/logout")
			.then(res => {
				return resolve(res.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
				// console.clear();
	});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL)
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//首頁---------------------------------------------------------------------------------------
export function getDashboard() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/auth/dashboard")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-------------------------------------------------------------------------------------------

//會員列表---------------------------------------------------------------------------------------
export function userList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function createUser(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/users", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserItem(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/users/"+ id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUser(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/users/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------


//身份證審核---------------------------------------------------------------------------------------
export function userIDCardList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/identity/idcard", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserIDCard(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/identity/idcard/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserIDCard(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/identity/idcard/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyIDCard(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/identity/idcard/verify/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//銀行帳號管理---------------------------------------------------------------------------------------
export function userBankList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payment/bank", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserBank(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/payment/bank/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserBank(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/payment/bank/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function verifyBank(id, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/payment/bank/verify/"+id, credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//合會檔案路徑管理---------------------------------------------------------------------------------------
export function userBidPathList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bid/path", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserBidPath(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/bid/path/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserBidPath(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/bid/path/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//組織圖檔案路徑管理---------------------------------------------------------------------------------------
export function userOrgChartPathList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/orgChart/path", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserOrgChartPath(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/orgChart/path/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserOrgChartPath(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/orgChart/path/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------

//觀光點數檔案路徑管理---------------------------------------------------------------------------------------
export function userTravelPointPathList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/travelPoint/path", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getUserTravelPointPath(id) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/travelPoint/path/"+id)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function updateUserTravelPointPath(id, credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/travelPoint/path/"+id, credentials, config)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//--------------------------------------------------------------------------------------------------