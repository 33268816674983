  
import React, { useState, useEffect } from 'react';
import Pagination from '@material-ui/lab/Pagination';

const Paginations = props => {
    const { total, current_page, per_page, pageCallback } = props;
    const [ dataCount, setDataCount ] = useState(0);
	const [ activePage, setActivePage ] = useState(1);
    const [ itemsCountPerPage, setItemsCountPerPage ] = useState(props.defaultPerPage ? props.defaultPerPage : 10);

    useEffect(() => {
        if(total && current_page && per_page) {
            setDataCount(total);
            setActivePage(current_page);
            setItemsCountPerPage(per_page);
        }
    }, [total, current_page, per_page]);

    const handlePageChange = (event, idx) => {
        pageCallback(idx);
        setActivePage(idx);
    }

    const handleGotoPage = (e) => {
        let value = parseInt(e.target.value);
        let max = parseInt(e.target.getAttribute('max'));
        let min = 1;
        if(value !== activePage){
            if(value > max){
                setActivePage(max);
                e.target.value = max;
            }else if(value < min){
                setActivePage(min);
                e.target.value = min;
            }else{
                setActivePage(value ? value : 1);
                e.target.value = value ? value : 1;
            }
            pageCallback(e.target.value);
        }else{
            return;
        }
    }

    const handleKeyUpGotoPage = (e) => {
        let value = parseInt(e.target.value);
        let max = parseInt(e.target.getAttribute('max'));
        let min = 1;
        if(e.keyCode === 13){
            if(value > max){
                setActivePage(max);
                e.target.value = max;
            }else if(value < min){
                setActivePage(min);
                e.target.value = min;
            }else{
                setActivePage(value ? value : 1);
                e.target.value = value ? value : 1;
            }
            pageCallback(e.target.value);
        }
    }

    return (<>
        { props.length !== 0 && (
            <div className="page-nav">
                <div>
                    顯示第 {(activePage-1)*itemsCountPerPage +1} 至 {activePage*itemsCountPerPage} 筆資料，共 {dataCount} 筆資料
                </div>
                <div className="d-flex align-items-center justify-content-center flex-wrap">
                    <Pagination count={Math.ceil(dataCount/itemsCountPerPage)} defaultPage={1} page={activePage} onChange={handlePageChange}/>
                    <div className="d-flex align-items-center">
                        前往
                        <input 
                            type="number"
                            className="goto-page mx-1"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            min="1"
                            max={Math.ceil(dataCount/itemsCountPerPage)}
                            defaultValue="1"
                            onBlur={(e) => handleGotoPage(e)}
                            onKeyUp={(e) => handleKeyUpGotoPage(e)}
                        />
                        頁
                    </div>
                </div>
            </div>
        ) }
    </>);
}

export default Paginations;