import React from 'react';
import { Provider } from 'react-redux';
import store from './js/store';
import Routes from './js/routes';

export default function App() {
    return (
        <Provider store={store}>
            <Routes />
        </Provider>
    );
}
