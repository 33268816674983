import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import ConfirmBox from "../../../components/ConfirmBox";

const data2 = [
    {
        id: 1,
        name: "管理員1號",
        username: "admin1",  
    },
    {
        id: 2,
        name: "管理員2號",
        username: "admin2",  
    },
]

export default function Create(props) {
    let mounted = true;
    const { setSnack } = props;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ data , setData ] = useState({});
    const [ confirmBoxToggle, setConfirmBoxToggle ] = useState(false);
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);

    const fetchData = useCallback(async() => {
        let result = await data2;
        if(mounted) {
            let resultData = result.find(e => e.id == id);
            setData(resultData);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let data = getValues();
        console.log(data);
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入管理員暱稱"});
            return;
        }
        if(values.password !== "" && values.repassword === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return;
        }
        if(values.password !== "" && values.repassword !== ""){
            if(values.repassword !== values.password){
                setSnack({open: true, text: "確認密碼錯誤"});
                return;
            }
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="管理員帳號">
                        <input
                            type="text"
                            defaultValue={data.username}
                            readOnly
                        />
                    </FormGroup>
                    <FormGroup label="管理員暱稱" required>
                        <input
                            name="name"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            defaultValue={data.name}
                            placeholder="請輸入管理員暱稱"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="登入密碼">
                        <input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="若無需更改請留空"
                            ref={register}
                        />
                        <div className="input-text">
                            <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </button>
                        </div>
                    </FormGroup>
                    <FormGroup label="確認密碼">
                        <input
                            name="repassword"
                            type={repasswordShow ? "text" : "password"}
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="若無需更改請留空"
                            ref={register}
                        />
                        <div className="input-text">
                            <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                {repasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </button>
                        </div>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
                <ConfirmBox
                    open={confirmBoxToggle}
                    confirmText="確定送出嗎?"
                    callback={value => handleConfirm(value)}
                    cancel={{show: true, type: "button", text: "取消"}}
                    confirm={{show: true, type: "submit", text: "確定"}}
                />
            </form>
        </div>
    )
}