import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import { StatList, StatItem } from "../../components/StatCard";

export default function Page(props) {
    const { loader, user } = props;
    const [ data, setData ] = useState({});

    // const fetchData = useCallback(async () => {
    //     loader(true);
    //     let result = await getDashboard();
    //     setData(result);
    //     loader(false);
    // }, []);

    // useEffect(() => {
    //     fetchData();
    // }, [])

    return (
        <div className="dashboard-page">
            <StatList>
                <StatItem icon="fal fa-users" content="250" label="總註冊人數" color="#5471f6" delay={0} />
                <StatItem icon="fal fa-flag-checkered" content="12" label="總標數" color="#46ca7b" delay={100} />
                <StatItem icon="fal fa-flag" content="10" label="進行中標數" color="#fd7177" delay={200} />
                <StatItem icon="fal fa-pennant" content="2" label="結束標數" color="#ffcc3d" delay={300} />
                <StatItem icon="fal fa-coin" content="4200" label="總發放獎金" color="#ffb37d" delay={400} />
                <StatItem icon="fal fa-gem" content="104561" label="目前流通點數" color="#46cac1" delay={500} />
                <StatItem icon="fal fa-hand-receiving" content="2000" label="已發放點數" color="#71cdfd" delay={600} />
                <StatItem icon="fal fa-sms" content="800" label="簡訊剩餘" color="#8696ec" delay={700} />
            </StatList>
        </div>
    );
}