import React from 'react'

const HamburgerMenu = (props) => {
    const { toggle, menuCallback } = props;

    return (
        <div className={`hamburger-box ${toggle ? "active" : ""}`} onClick={() => menuCallback()}>
            <div className="hamburger-menu">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default HamburgerMenu;
