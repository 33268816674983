import React, { useState } from 'react';

const Thead = ({ column, checkbox, checkboxCallback, orderByCallback }) => {
    const [orderBy, setOrderBy] = useState({order: -1, sort: 'desc'});

    const handleOrderByChange = (key, item) => {
        let data = {order: item.key};
        if(orderBy.order !== item.key) {
            data = {...data, sort: 'desc'}
        } else {
            data = {...data, sort: orderBy.sort === 'asc' ? 'desc' : 'asc'}
        }
        setOrderBy(data);
        orderByCallback(data);
    }
    
    return (
        <thead>
            { column && (
                <tr>
                    { checkbox && (
                        <th style={{minWidth: '47.2px', width: '47.2px'}}>
                            <input type="checkbox" onChange={(e) => checkboxCallback(e.target.checked)} />
                        </th>
                    ) }
                    { column.map((item, key) => (
                        <th key={key} onClick={() => handleOrderByChange(key, item)}>
                            {item.name}
                            <i className={`fas fa-sort-up ${orderBy.order === item.key && orderBy.sort === 'asc' && 'text-dark'}`}></i>
                            <i className={`fas fa-sort-down ${orderBy.order === item.key && orderBy.sort === 'desc' && 'text-dark'}`}></i>
                        </th>
                    )) }
                </tr>
            )}
        </thead>
    );
}

export default Thead;