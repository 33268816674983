import React, { useState } from "react";
import { BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import routes from './routes';
import PublicRoute from './Public';
import PrivateRoute from './Private';
import Loader from '../common/Loader';
import SnackBar from "../common/SnackBar";
import Layout from '../layout';

const Routes = withRouter(({...props}) => {
    const { history } = props;
    const { location } = history;
    const [ snack, setSnack ] = useState({open: false, color:'', text:''});
    const [ loader, setLoader ] = useState(false);

    const initialProps = { setSnack, setLoader };

    return (
        <Layout {...initialProps}>
            <AnimatePresence exitBeforeEnter initial={false}>
                <Switch location={location} key={location.pathname}>
                    {routes.map((route,i) => {
                        if(route.auth) {
                            return <PrivateRoute key={i} index={i} location={location} {...route} {...initialProps} />
                        }else{
                            return <PublicRoute key={i} {...route} {...initialProps} />
                        }
                    })}
                </Switch>
            </AnimatePresence>
            <SnackBar {...snack} callback={(e) => setSnack(e)}/>
            <Loader loader={loader} setLoader={setLoader} setSnack={setSnack}/>
        </Layout>
    )
})

const App = () => {
    return (<>
        <Router>
            <Routes />
        </Router>
    </>);
}

export default App;