import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import Upload from "../../../components/Upload";
import { FormGroup, InputGroup } from '../../../components/FormGroup';

const data2 = [
    {
        id: 1,
        status: "1",
        sort: "1",
        image: "https://via.placeholder.com/768x307",
        created_at: "2021/06/08 15:10:31",
    },
    {
        id: 2,
        status: "0",
        sort: "2",
        image: "https://via.placeholder.com/768x308",
        created_at: "2021/06/07 15:10:31",
    },
]

export default function Create() {
    let mounted = true;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);
    const [ data, setData ] = useState({});

    const fetchData = useCallback(async() => {
        let result = await data2;
        if(mounted) {
            let resultData = result.find(e => e.id == id);
            setData(resultData);
            setShowToggle(resultData.status === "1" ? true : false);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架">
                        <label className="switch" >
                            <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="排序" required>
                        <input
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入排序"
                            defaultValue={data.sort}
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="上傳圖片" desc="請上傳最小寬 768px*307px JPEG/PNG 圖檔" required>
                        <Upload
                            id="banner"
                            maxWidth="auto"
                            maxHeight="800px"
                            name="banner"
                            valid={{width: 768, height: 307, type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("banner", "")}
                            defaultValue={data.image}
                        />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}