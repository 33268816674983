import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

// import 'react-photoswipe/lib/photoswipe.css';
// import {PhotoSwipeGallery} from 'react-photoswipe';

export default function Prize(props) {
    const { setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ open, setOpen ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(false);
      

    let options = {
        //http://photoswipe.com/documentation/options.html
        bgOpacity: 0.55,
        captionEl: false,
        closeOnScroll: false,
    };

    const getThumbnailContent = (item) => {
        return (
            <img src={item.thumbnail} height={80}/>
        );
    }

    const fakeData = {
        active_page: 1,
        countPerPage: 10,
        total_rows: 1,
        data: [
            {
                id: 1,
                status: "1",
                sort: "1",
                image: "https://via.placeholder.com/768x307",
                created_at: "2021/06/08 15:10:31",
            },
            {
                id: 2,
                status: "0",
                sort: "2",
                image: "https://via.placeholder.com/768x308",
                created_at: "2021/06/07 15:10:31",
            },
        ]
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'status',
            name: "狀態",
            view: (data) => {
                return data.status === "1" ? <span className="text-success">上架</span> : <span className="text-danger">下架</span> ;
            }
        },
        {
            key: 'sort',
            name: "排序",
        },
        {
            key: 'image',
            name: "縮圖",
            view: (data) => {
                return (
                    <img src={data.image} height="80" />
                    // <PhotoSwipeGallery
                    //     items={[
                    //         {
                    //             src: data.image,
                    //             thumbnail: data.image,
                    //             w: 600,
                    //             h: 450,
                    //             title: 'Image 1'
                    //         },
                    //     ]}
                    //     options={options}
                    //     thumbnailContent={getThumbnailContent}
                    // />
                );
            }
        },
        {
            key: 'created_at',
            name: "建立時間",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/application/Banner/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改廣告輪播</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await getAdminList(callbackParams ? callbackParams : params);
        let reuslt = await fakeData;
        setData(reuslt);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to={`/application/Banner/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增廣告輪播'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-image ml-1 mr-2"></i>廣告輪播列表</>}
                checkbox={false}
                maxWidth="992px"
                searchbar={{date: true, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}