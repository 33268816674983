import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { useParams } from 'react-router-dom';
import { FormGroup, InputGroup } from '../../../../components/FormGroup';
import ConfirmBox from "../../../../components/ConfirmBox";
import Upload from '../../../../components/Upload';
import bankList from "../../../../common/TaiwanBankCode";
import { getUserBank, updateUserBank } from "../../../../services/authService";

export default function Edit(props) {
    let mounted = true;
    const { id } = useParams();
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ data, setData ] = useState({
        image: "",
    });

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getUserBank(id);
        if(mounted) {
            setData(result.data);
        }
        setLoader(false);
    }, [])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();

        if(values.image.length === 0) {
            delete values.image;
        }

        const formData = new FormData();
		Object.keys(values).map(function(key, index) {
            if(key === 'image') return formData.append(key, values.image[0]);
			return formData.append(key, values[key]);
        });

        setLoader(true);
        updateUserBank(id, formData).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.bankname === ""){
            setSnack({open: true, text: "請選擇銀行"});
            return;
        }
        if(values.bankbranch === ""){
            setSnack({open: true, text: "請輸入分行名稱"});
            return;
        }
        if(values.bankaccount === ""){
            setSnack({open: true, text: "請輸入帳戶戶名"});
            return;
        }
        if(values.bankid === ""){
            setSnack({open: true, text: "請輸入銀行帳號"});
            return;
        }
        if(values.image.length === 0 && data.image === ""){
            setSnack({open: true, text: "請上傳圖片 (銀行存摺)"});
            return;
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="member-bankPayment-edit-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="會員帳號">
                        <div style={{height: "42.8px"}} className="d-flex align-items-center">{data.phone}</div>
                    </FormGroup>
                    <FormGroup label="會員姓名">
                        <div style={{height: "42.8px"}} className="d-flex align-items-center">{data.name}</div>
                    </FormGroup>
                    <FormGroup label="銀行名稱" required>
                        <select
                            name="bankname"
                            value={data.bankname}
                            onChange={(e) => setData({...data, bankname: e.target.value})}
                            ref={register}
                        >
                            <option value="" selected disabled>請選擇銀行</option>
                            { bankList.map((item, key) => <option value={`${item.code} ${item.name}`} key={key}>{item.code} {item.name}</option>) }
                        </select>
                    </FormGroup>
                    <FormGroup label="分行名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankbranch"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入分行名稱"
                                defaultValue={data.bankbranch}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="帳戶戶名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankaccount"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入帳戶戶名"
                                defaultValue={data.bankaccount}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="銀行帳號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bankid"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入銀行帳號"
                                defaultValue={data.bankid}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="銀行存摺">
                        <Upload
                            id="image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("image", "")}
                            defaultValue={data.image && data.image}
                        />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
            </form>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定修改嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}
