import React, { useState } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const BackToTop = (props) => {
    const { callback, toggle } = props;
    const [ disabled, setDisabled ] = useState(false);

    const scrollTop = () =>{
        setDisabled(true);
        callback();
        setTimeout(() => {
            setDisabled(false);
        }, 300)
    };

    return (
        <button className={`back-to-top-btn ${toggle ? 'active' : ''}`} disabled={disabled} onClick={(e) => scrollTop(e)}>
            <i className="fal fa-chevron-up"></i>
        </button>
    )
}

export default BackToTop;