import React, { useState, useEffect, useCallback } from 'react';
import useForm from "react-hook-form";
import { useParams } from 'react-router-dom';
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import ConfirmBox from "../../../components/ConfirmBox";
import { getUserOrgChartPath, updateUserOrgChartPath } from "../../../services/authService";

export default function Edit(props) {
    let mounted = true;
    const { id } = useParams();
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ data, setData ] = useState({});

    const fetchData = useCallback(async () => {
        setLoader(true);
        let result = await getUserOrgChartPath(id);
        if(mounted) {
            setData(result.data);
        }
        setLoader(false);
    }, [])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = () => {
        let values = getValues();

        if(values.file.length === 0){
            delete values.file;
        }

        const formData = new FormData();
		Object.keys(values).map(function(key, index) {
            if(key === 'file') return formData.append(key, values.file[0]);
			return formData.append(key, values[key]);
        });

        setLoader(true);
        updateUserOrgChartPath(id, formData).then(res => {
            setSnack({open: true, text: res.messages});
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        console.log(data);
        if(values.file.length === 0 && data.url === ""){
            setSnack({open: true, text: "請先上傳檔案"});
            return;
        }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="member-bidUrl-edit-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="會員帳號">
                        <div className="pt-1" style={{flex: "1"}}>{data.phone}</div>
                    </FormGroup>
                    <FormGroup label="會員姓名">
                        <div className="pt-1" style={{flex: "1"}}>{data.name}</div>
                    </FormGroup>
                    <FormGroup label="上傳檔案" >
                        <div>
                        <input
                            tabIndex="1"
                            style={{flex: "1"}}
                            name="file"
                            type="file"
                            ref={register}
                        />
                        <div className="font-size-xs text-danger">* 檔案格式建議為PDF</div>
                        </div>
 
                    </FormGroup>
                    <FormGroup label="檔案連結">
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                name="url"
                                rows="3"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入檔案連結"
                                defaultValue={data.url}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="目前連結">
                        {
                            data.url ? (
                                <a href={data.url} target="_blank" className="pt-1 d-block" style={{flex: "1"}} rel="noreferrer">{data.url}</a>
                            ) : (
                                <div className="pt-1" style={{flex: "1"}}>尚未新增</div>
                            )
                        }
                    </FormGroup>
                    <FormGroup label="備註">
                        <InputGroup>
                            <textarea
                                tabIndex="1"
                                name="note"
                                rows="6"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入備註"
                                defaultValue={data.note}
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
            </form>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定修改嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}
