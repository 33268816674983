import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrivateLayout from './Private';
import PublicLayout from './Public';
import { fetchUser } from '../services/authService';

function Layout(props) {
    const { isAuthenticated, user, children, dispatch } = props;

    useEffect(() => {
		if (isAuthenticated && !user.username) {
			dispatch(fetchUser());
		}
    }, [isAuthenticated]);
    
    if (isAuthenticated) {
        return <PrivateLayout {...props}>{children}</PrivateLayout>
    }
    return <PublicLayout {...props}>{children}</PublicLayout>
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth.user,
    }
}
  
export default withRouter(connect(mapStateToProps)(Layout));