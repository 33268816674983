import React, { useState } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import Editor from '../../../components/Editor';

export default function Create() {
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);
    const [ topToggle, setTopToggle ] = useState(false);
    const [ newsType, setNewsType ] = useState("一般");
    const [ editorData, setEditorData ] = useState("");

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架">
                        <label className="switch" >
                            <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="置頂">
                        <label className="switch" >
                            <input type="checkbox" checked={topToggle} onChange={() => setTopToggle(!topToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="選擇分類" required>
                        <div className="radio-container">
                            <div className="radio-box">
                                <input type="radio" value="一般" checked={newsType === "一般"} name="news_type" id="news_type1" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type1">一般</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="活動" checked={newsType === "活動"} name="news_type" id="news_type2" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type2">活動</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="系統" checked={newsType === "系統"} name="news_type" id="news_type3" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type3">系統</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="其它" checked={newsType === "其它"} name="news_type" id="news_type4" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type4">其它</label>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup label="標題" required>
                        <input
                            name="name"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入標題"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="內容" required>
                        <Editor data={editorData} callback={(data) => setEditorData(data)} />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}