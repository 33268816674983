import React, { useState, useEffect } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import Upload from '../../../components/Upload';
import ConfirmBox from "../../../components/ConfirmBox";
import { createUser } from "../../../services/authService";
import bankList from "../../../common/TaiwanBankCode";

export default function Create(props) {
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ imageClear, setImageClear ] = useState(false);

    const onSubmit = () => {
        let data = getValues();
        const formData = new FormData();
		Object.keys(data).map(function(key, index) {
            if(key === 'identity_image_front') return formData.append(key, data.identity_image_front[0]);
            if(key === 'identity_image_back') return formData.append(key, data.identity_image_back[0]);
            if(key === 'bank_image') return formData.append(key, data.bank_image[0]);
			return formData.append(key, data[key]);
        });
        reset();
        setImageClear(true);
        setTimeout(() => setImageClear(false), 0);

        setValue("identity_image_front", "")
        setValue("identity_image_back", "")
        setValue("bank_image", "");

        setLoader(true);
        createUser(formData).then(res => {
            setSnack({open: true, text: res.messages});
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const handleConfirm = value => {
        setConfirmBoxToggle(value.open);
        if(value.confirm){
            onSubmit();
        }
    }

    const handleCheck = () => {
        let values = getValues();
        if(values.name === ""){
            setSnack({open: true, text: "請輸入真實姓名"});
            return;
        }
        if(values.password === ""){
            setSnack({open: true, text: "請輸入登入密碼"});
            return;
        }
        if(values.password_confirmation === ""){
            setSnack({open: true, text: "請輸入確認密碼"});
            return;
        }
        if(values.password !== "" && values.password_confirmation !== ""){
            if(values.password_confirmation !== values.password){
                setSnack({open: true, text: "確認密碼不一致"});
                return;
            }
        }
        if(values.phone === ""){
            setSnack({open: true, text: "請輸入電話號碼"});
            return;
        }
        if(values.invitation_token === ""){
            setSnack({open: true, text: "請輸入邀請碼"});
            return;
        }
        // if(values.identity_name === ""){
        //     setSnack({open: true, text: "請輸入身份證姓名"});
        //     return;
        // }
        // if(values.identity_number === ""){
        //     setSnack({open: true, text: "請輸入身份證字號"});
        //     return;
        // }
        // if(values.identity_image_front.length === 0){
        //     setSnack({open: true, text: "請上傳身份證正面圖片"});
        //     return;
        // }
        // if(values.identity_image_back.length === 0){
        //     setSnack({open: true, text: "請上傳身份證反面圖片"});
        //     return;
        // }
        // if(values.bank_bankname === ""){
        //     setSnack({open: true, text: "請輸入銀行名稱"});
        //     return;
        // }
        // if(values.bank_bankbranch === ""){
        //     setSnack({open: true, text: "請輸入分行名稱"});
        //     return;
        // }
        // if(values.bank_bankaccount === ""){
        //     setSnack({open: true, text: "請輸入帳戶戶名"});
        //     return;
        // }
        // if(values.bank_bankid === ""){
        //     setSnack({open: true, text: "請輸入銀行帳號"});
        //     return;
        // }
        // if(values.bank_image.length === 0){
        //     setSnack({open: true, text: "請上傳銀行存摺圖片"});
        //     return;
        // }
        setConfirmBoxToggle(true);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="真實姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入真實姓名"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="登入密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password"
                                type={passwordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入登入密碼"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="確認密碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="password_confirmation"
                                type={repasswordShow ? "text" : "password"}
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入確認密碼"
                                ref={register}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                    {repasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="電話號碼" required>
                        <select
                            name="phone_code"
                            className="mr-1"
                            defaultValue="+886"
                            style={{maxWidth: " 90px"}}
                            ref={register}
                        >
                            <option value="+886">+886</option>
                        </select>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="phone"
                                type="tel"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入電話號碼"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="邀請碼" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="invitation_token"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入邀請碼"
                                defaultValue="asiayofun"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    {/* <FormGroup label="驗證類別" required>
                        <select
                            name="identity_type"
                            defaultValue="idcard"
                            ref={register}
                        >
                            <option value="idcard">身份證</option>
                        </select>
                    </FormGroup>
                    <FormGroup label="身份證姓名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="identity_name"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入身份證姓名"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="身份證字號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="identity_number"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入身份證字號"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="身份證正面" required>
                        <Upload
                            id="identity_image_front"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="identity_image_front"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("identity_image_front", "")}
                            clear={imageClear}
                        />
                    </FormGroup>
                    <FormGroup label="身份證反面" required>
                        <Upload
                            id="identity_image_back"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="identity_image_back"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("identity_image_back", "")}
                            clear={imageClear}
                        />
                    </FormGroup>
                    <FormGroup label="銀行名稱" required>
                        <select
                            name="bank_bankname"
                            defaultValue=""
                            ref={register}
                        >
                            <option value="" disabled>請選擇銀行</option>
                            { bankList.map((item, key) => <option value={`${item.code} ${item.name}`} key={key}>{item.code} {item.name}</option>) }
                        </select>
                    </FormGroup>
                    <FormGroup label="分行名稱" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bank_bankbranch"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入分行名稱"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="帳戶戶名" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bank_bankaccount"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入帳戶戶名"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="銀行帳號" required>
                        <InputGroup>
                            <input
                                tabIndex="1"
                                name="bank_bankid"
                                type="text"
                                spellCheck="false"
                                autoComplete="off"
                                placeholder="請輸入銀行帳號"
                                ref={register}
                            />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup label="上傳銀行存摺" required>
                        <Upload
                            id="bank_image"
                            maxWidth="auto"
                            maxHeight="200px"
                            name="bank_image"
                            valid={{type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("bank_image", "")}
                            clear={imageClear}
                        />
                    </FormGroup> */}
                </div>
                <div className="text-right">
                    <button type="button" className="submit-btn" onClick={() => handleCheck()}>送出</button>
                </div>
            </form>
            <ConfirmBox
                open={confirmBoxToggle}
                confirmText="確定新增嗎?"
                callback={value => handleConfirm(value)}
                cancel={{show: true, type: "button", text: "取消"}}
                confirm={{show: true, type: "submit", text: "確定"}}
            />
        </div>
    )
}