import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

export default function Prize(props) {
    const { setLoader } = props;
    const [ open, setOpen ] = useState(false);
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();

    const fakeData = {
        active_page: 1,
        countPerPage: 10,
        total_rows: 1,
        data: [
            {
                id: 1,
                name: "管理員1號",
                username: "admin1",  
            },
            {
                id: 2,
                name: "管理員2號",
                username: "admin2",  
            },
        ]
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'name',
            name: "管理員暱稱",
        },
        {
            key: 'username',
            name: "帳號",
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/system/Administrator/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        // let result = await getAdminList(callbackParams ? callbackParams : params);
        let reuslt = await fakeData;
        setData(reuslt);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to={`/system/Administrator/create`} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增管理員'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-user-shield ml-1 mr-2"></i>管理員列表</>}
                checkbox={false}
                maxWidth="992px"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}