import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../../components/FormTables/Table';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

import PhotoModal from '../../../../components/PhotoModal';
import { userBankList, verifyBank } from '../../../../services/authService';

export default function Page(props) {
    const { setSnack, setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ open, setOpen ] = useState(false);
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "會員帳號": item.phone,
                        "會員姓名": item.name,
                        "銀行驗證": handleStatus(item.identity_verified),
                        "銀行名稱": item.bankname,
                        "分行名稱": item.bankbranch,
                        "帳戶戶名": item.bankaccount,
                        "銀行帳號": item.bankid,
                        "銀行存摺": item.image,
                        "更新時間": moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss"),
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const handleStatus = (status) => {
        switch (status) {
            case -1: return "驗證失敗";
            case 0: return "未驗證";
            case 1: return "驗證中";
            case 2: return "已驗證";
            default: return;
        }
    }

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'phone',
            name: "會員帳號",
        },
        {
            key: 'name',
            name: "會員姓名",
        },
        {
            key: 'identity_verified',
            name: "銀行驗證",
            view: (data) => {
                switch(data.verified) {
                    case -1: return <span className="text-danger">驗證失敗</span>;
                    case 0: return <span className="text-secondary">未驗證</span>;
                    case 1: return <span className="text-warning">驗證中</span>;
                    case 2: return <span className="text-success">已驗證</span>;
                    default: return;
                }
            }
        },
        {
            key: 'bankname',
            name: "銀行名稱",
            view: (data) => {
                return data.bankname ? data.bankname : "-"
            }
        },
        {
            key: 'bankbranch',
            name: "分行名稱",
            view: (data) => {
                return data.bankbranch ? data.bankbranch : "-"
            }
        },
        {
            key: 'bankaccount',
            name: "帳戶戶名",
            view: (data) => {
                return data.bankaccount ? data.bankaccount : "-"
            }
        },
        {
            key: 'bankid',
            name: "銀行帳號",
            view: (data) => {
                return data.bankid ? data.bankid : "-"
            }
        },
        {
            key: 'image',
            name: "銀行存摺",
            view: (data) => {
                return (<>
                    {data.image && data.image !== "" ? <PhotoModal src={data.image} height="100"/> : "-"}
                </>)
            }
        },
        {
            key: 'updated_at',
            name: "更新時間",
            view: (data) => {
                return moment(data.updated_at).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => {
                const verifyButton = () => {
                    switch (data.verified){
                        case -1: return (<button type="button" className="common-btn table-btn black ml-2" onClick={() => handleVerify(data.id, "1")}><i className="fas fa-backward mr-1"></i>返回驗證中</button>);
                        case 0: return;
                        case 1: return (<>
                            <button type="button" className="common-btn table-btn green ml-2" onClick={() => handleVerify(data.id, "2")}><i className="fas fa-check mr-1"></i>驗證成功</button>
                            <button type="button" className="common-btn table-btn red ml-2" onClick={() => handleVerify(data.id, "-1")}><i className="fas fa-times mr-1"></i>驗證失敗</button>
                        </>);
                        case 2: return (<button type="button" className="common-btn table-btn black ml-2" onClick={() => handleVerify(data.id, "1")}><i className="fas fa-backward mr-1"></i>取消驗證</button>);
                        default: return;
                    }
                }
                return (
                    <div className="d-flex justify-content-center">
                        <Link to={`/member/BankPayment/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                        { verifyButton() }
                    </div>
                )
            }
        },
    ];

    const handleVerify = (id, status) => {
        setLoader(true);
        verifyBank(id, {verified: status}).then(res => {
            setSnack({open: true, text: res.messages});
            fetchData();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, text: err.messages.error});
            setLoader(false);
        })
    }

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await userBankList(callbackParams ? callbackParams : params);
        // let reuslt = await fakeData;
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <CSVLink filename="銀行帳戶列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-money-check-edit ml-1 mr-2"></i>銀行帳戶列表</>}
                checkbox={false}
                maxWidth="unset"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}
