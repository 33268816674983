import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import FormTable from '../../../components/FormTables/Table';
import { CSVLink } from "react-csv";
import moment from 'moment/moment.js';

import { SpeedDial } from '@material-ui/lab';
import { SpeedDialIcon } from '@material-ui/lab';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';

import { userList } from '../../../services/authService';

export default function Prize(props) {
    const { setLoader } = props;
    const [ data, setData ] = useState({});
    const [ params, setParams ] = useState();
    const [ open, setOpen ] = useState(false);
    const [ exportData, setExportData ] = useState([]);

    useEffect(() => {
        if(data.data !== undefined) {
            let result = [];
            data.data.map((item, key) => {
                result.push(
                    {
                        "#": item.id,
                        "邀請碼": item.invitation_token,
                        "帳號": item.phone,
                        "真實姓名": item.name,
                        "推薦人": item.parent_name,
                        "銀行驗證": item.bank_verified === "1" ? "已驗證" : "未驗證",
                        "身分證驗證": item.identity_verified === "1" ? "已驗證" : "未驗證",
                        "會員等級": item.role,
                        "註冊時間": moment(data.created_at).format("YYYY-MM-DD HH:mm:ss"),
                    }
                )
            })
            setExportData(result);
        }
    }, [data])

    const column = [
        {
            key: 'id',
            name: "#",
        },
        {
            key: 'invitation_token',
            name: "邀請碼",
        },
        {
            key: 'phone',
            name: "帳號",
        },
        {
            key: 'name',
            name: "真實姓名",
        },
        {
            key: 'parent_name',
            name: "推薦人",
            view: (data) => {
                return data.parent_name ? data.parent_name : "-";
            }
        },
        {
            key: 'bank_verified',
            name: "銀行驗證",
            view: (data) => {
                switch(data.bank_verified) {
                    case -1: return <span className="text-danger">驗證失敗</span>;
                    case 0: return <span className="text-secondary">未驗證</span>;
                    case 1: return <span className="text-warning">驗證中</span>;
                    case 2: return <span className="text-success">已驗證</span>;
                }
            }
        },
        {
            key: 'identity_verified',
            name: "身分證驗證",
            view: (data) => {
                switch(data.identity_verified) {
                    case -1: return <span className="text-danger">驗證失敗</span>;
                    case 0: return <span className="text-secondary">未驗證</span>;
                    case 1: return <span className="text-warning">驗證中</span>;
                    case 2: return <span className="text-success">已驗證</span>;
                }
            }
        },
        {
            key: 'role',
            name: "會員等級",
        },
        {
            key: 'created_at',
            name: "註冊時間",
            view: (data) => {
                return moment(data.created_at).format("YYYY-MM-DD HH:mm:ss");
            }
        },
        {
            key: 'tools',
            name: '功能',
            view: (data) => (<>
                <div className="d-flex justify-content-center">
                    <Link to={`/member/List/edit/${data.id}`} className="common-btn table-btn black"><i className="fas fa-pen mr-1"></i>修改</Link>
                </div>
            </>)
        },
    ];

    const fetchData = useCallback(async (callbackParams) => {
        setLoader(true);
        let result = await userList(callbackParams ? callbackParams : params);
        // let reuslt = await fakeData;
        setData(result);
        setLoader(false);
    }, []);

    const handleCallback = (callbackParams) => {
        setParams(callbackParams);
        fetchData(callbackParams);
    }

    const actions = [
        { icon: <Link to="/member/List/create" className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><AddIcon /></Link>, name: '新增會員'},
        { icon: <CSVLink filename="會員列表.csv" data={exportData} className="d-flex justify-content-center align-items-center" style={{width: "40px", height: "40px"}}><GetAppIcon /></CSVLink>, name: '匯出資料'},
    ];

    return (
        <div className="tools-coupons-page">
            <FormTable 
                column={column}
                data={data}
                title={<><i className="fas fa-users ml-1 mr-2"></i>會員列表</>}
                checkbox={false}
                maxWidth="unset"
                searchbar={{date: false, keyword: true}}
                defaultPerPage={10}
                callback={(callbackParams) => handleCallback(callbackParams)}
            />
            <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                className="tools-btn"
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                onClick={() => setOpen(!open)}
                open={open}
            >
                {actions.map((item, key) => 
                <SpeedDialAction
                    key={key}
                    icon={item.icon}
                    tooltipTitle={item.name}
                    tooltipOpen
                />)}
            </SpeedDial>
        </div>
    )
}