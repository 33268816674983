import React, { useState, useEffect } from "react";

const SnackBar = ({ open, color, text, callback }) => {
    const [ toggle, setToggle ] = useState(false);

    useEffect(() => {
        let timeout;
        clearTimeout(timeout);

        const handleToggle = (open) => {
            if(!open) return;
            // Open
            setToggle(true);
            document.body.classList.add('modal-open');  
            // SetTimeOut
            timeout = setTimeout(() => {
                document.body.classList.remove('modal-open');
                callback({open: false, color:'', text:''});
                setToggle(false);
            }, 2000);
        }

        handleToggle(open);

        return () => { clearTimeout(timeout) }
    }, [open]);



    return (
        toggle &&
        <div className={`snack ${toggle ? 'open' : ''}`}>
            <div className={`snack-bar ${color ? `bar-${color}` : ''}`} >
                {text ? text : 'Waiting'}
            </div>
        </div>
    )
}

export default SnackBar;