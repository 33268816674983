import React, { useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { useCountUp } from 'react-countup';
import PopoverBox from './PopoverBox';

const StatList = ({ children }) => {
    return (
        <ul className="stat-list">
            { children }
        </ul>
    )
}

const StatItem = (props) => {
    const { icon, content, label, color, delay } = props;
    const { countUp, update } = useCountUp({
        start: 0,
        end: 0,
        delay: 0,
        duration: 2,
        separator: ",",
        useEasing: true,
    });

    useEffect(() => {
        let timeout;
        timeout = setTimeout(() => {
            update(content);
        }, delay)
        return () => {
            clearTimeout(timeout);
        }
    }, [content])

    return (
        <li className="stat-item">
            <Fade delay={delay} bottom>
                <div>
                    <div className="d-flex align-items-center" style={{color: color}}>
                        <i className={icon}></i>
                        <div className="amount">{countUp}</div>
                    </div>
                    <div className="label">{label}</div>
                </div>
            </Fade>
        </li>
    )
}


export { StatList, StatItem };
