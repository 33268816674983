import React, { useState } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';

export default function Create() {
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ repasswordShow, setRepasswordShow ] = useState(false);

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="管理員帳號" required>
                        <input
                            name="username"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入管理員帳號"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="管理員暱稱" required>
                        <input
                            name="name"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入管理員暱稱"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="登入密碼" required>
                        <input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入登入密碼"
                            ref={register}
                        />
                        <div className="input-text">
                            <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </button>
                        </div>
                    </FormGroup>
                    <FormGroup label="確認密碼" required>
                        <input
                            name="repassword"
                            type={repasswordShow ? "text" : "password"}
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入確認密碼"
                            ref={register}
                        />
                        <div className="input-text">
                            <button type="button" className="view-pass-btn" onClick={() => setRepasswordShow(!repasswordShow)}>
                                {repasswordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                            </button>
                        </div>
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}