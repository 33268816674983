import React, { useState, useEffect } from 'react';
import Thead from './Thead';
import Tbody from './Tbody';
import Paginations from './Paginations';
import OnePageShow from './OnePageShow';
import SearchBar from './SearchBar';

const Table = (props) => {
    const { column, data, title, maxWidth, checkbox, searchbar, defaultPerPage, callback } = props;
    const [ callbackParams, setCallbackParams ] = useState({countPerPage: defaultPerPage});
    const [ checkboxArray, setCheckboxArray ] = useState([]);

    useEffect(() => {
        callback && callbackParams && callback(callbackParams);
    }, [callbackParams]);

    const handleCheckbox = item => {
        if(item.key === 'all') {
            if(item.toggle) {
                let checkboxKey = [];
                for (const key of data.data.keys()) checkboxKey.push(key);
                setCheckboxArray(checkboxKey);
            } else {
                setCheckboxArray([])
            }
        } else {
            let array = Array.from(checkboxArray);
            let index = array.indexOf(item.key);
            if(item.toggle && index < 0) {
                array.push(item.key);
                setCheckboxArray(array);
            } else {
                if (index > -1) array.splice(index, 1);
                setCheckboxArray(array);
            }
        }
    }

    return (
        <div className="data-table" style={{maxWidth: maxWidth ? maxWidth : ""}}>
            { searchbar && <SearchBar searchCallback={(searchResult) => setCallbackParams({...callbackParams, search: searchResult, page: 1})} searchbar={searchbar} /> }
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <h5 className="table-title">{title}</h5>
                <OnePageShow perPageCallback={(page) => setCallbackParams({...callbackParams, countPerPage: page})} defaultPerPage={defaultPerPage ? defaultPerPage : 10} />
            </div>
            <div className="table-responsive">
                <table className="table table-striped table-hover">
                    <Thead
                        column={column}
                        checkbox={checkbox}
                        checkboxCallback={e => handleCheckbox({key: 'all', toggle: e})}
                        orderByCallback={(e) => setCallbackParams({...callbackParams, orderBy: e})}
                    />

                    <Tbody
                        column={column}
                        data={data.data}
                        checkbox={checkbox}
                        checkboxArray={checkboxArray}
                        checkboxCallback={e => handleCheckbox(e)}
                    />
                </table>
            </div>
            <Paginations {...data} pageCallback={(e) => setCallbackParams({...callbackParams, page: e})}/>
        </div>
    )
}

export default Table;