import Login from '../modules/Auth/Login';

import Dashboard from '../modules/Dashboard';

// System----------------------------------------------
import SystemAdministrator from '../modules/System/Administrator';
import SystemAdministratorCreate from '../modules/System/Administrator/Create';
import SystemAdministratorEdit from '../modules/System/Administrator/Edit';

// Member----------------------------------------------
import MemberList from '../modules/Member/List';
import MemberListEdit from '../modules/Member/List/Edit';
import MemberListTeam from '../modules/Member/List/Team';
import MemberListCreate from '../modules/Member/List/Create';

import MemberMailAuth from '../modules/Member/MailAuth';
import MemberIdentity from '../modules/Member/Identity';
import MemberIdentityEdit from '../modules/Member/Identity/Edit';
import MemberPaymentBank from '../modules/Member/Payment/Bank';
import MemberPaymentBankEdit from '../modules/Member/Payment/Bank/Edit';
import MemberElectronicWalletAuth from '../modules/Member/ElectronicWalletAuth';
import MemberBidUrl from '../modules/Member/BidUrl';
import MemberBidUrlEdit from '../modules/Member/BidUrl/Edit';
import MemberOrgChartUrl from '../modules/Member/OrgChartUrl';
import MemberOrgChartUrlEdit from '../modules/Member/OrgChartUrl/Edit';
import MemberTravelPointUrl from '../modules/Member/TravelPointUrl';
import MemberTravelPointUrlEdit from '../modules/Member/TravelPointUrl/Edit';

// Finance----------------------------------------------
import FinanceAssets from '../modules/Finance/Assets';
import FinanceTransfer from '../modules/Finance/Transfer';
import FinanceMemberBonus from '../modules/Finance/MemberBonus';
import FinanceExchange from '../modules/Finance/Exchange';

// Bid----------------------------------------------
import BidList from '../modules/Bid/List';
import BidSuccessfulBidder from '../modules/Bid/SuccessfulBidder';
import BidConfig from '../modules/Bid/Config';

// Application----------------------------------------------
import ApplicationNews from '../modules/Application/News';
import ApplicationNewsCreate from '../modules/Application/News/Create';
import ApplicationNewsEdit from '../modules/Application/News/Edit';
import ApplicationNotification from '../modules/Application/Notification';
import ApplicationNotificationCreate from '../modules/Application/Notification/Create';
import ApplicationNotificationEdit from '../modules/Application/Notification/Edit';
import ApplicationBanner from '../modules/Application/Banner';
import ApplicationBannerCreate from '../modules/Application/Banner/Create';
import ApplicationBannerEdit from '../modules/Application/Banner/Edit';
import ApplicationUserGuide from '../modules/Application/UserGuide';
import ApplicationContract from '../modules/Application/Contract';
import ApplicationAboutUs from '../modules/Application/AboutUs';
import ApplicationCompanyAccount from '../modules/Application/CompanyAccount';
import ApplicationSms from '../modules/Application/Sms';

const routes = [
    {
        path: '/',
        exact: true,
        auth: false,
        component: Login
    },
    {
        path: '/login',
        exact: true,
        auth: false,
        component: Login
    },
    {
        path: '/dashboard',
        exact: true,
        auth: true,
        component: Dashboard,
    },
    {
        path: '/system/Administrator',
        exact: true,
        auth: true,
        component: SystemAdministrator,
    },
    {
        path: '/system/Administrator/create',
        exact: true,
        auth: true,
        component: SystemAdministratorCreate,
    },
    {
        path: '/system/Administrator/edit/:id',
        exact: true,
        auth: true,
        component: SystemAdministratorEdit,
    },
    {
        path: '/member/List',
        exact: true,
        auth: true,
        component: MemberList,
    },
    {
        path: '/member/List/edit/:id',
        exact: true,
        auth: true,
        component: MemberListEdit,
    },
    {
        path: '/member/List/team/:id',
        exact: true,
        auth: true,
        component: MemberListTeam,
    },
    {
        path: '/member/List/create',
        exact: true,
        auth: true,
        component: MemberListCreate,
    },
    {
        path: '/member/MailAuth',
        exact: true,
        auth: true,
        component: MemberMailAuth,
    },
    {
        path: '/member/Idcard',
        exact: true,
        auth: true,
        component: MemberIdentity,
    },
    {
        path: '/member/Idcard/edit/:id',
        exact: true,
        auth: true,
        component: MemberIdentityEdit,
    },
    {
        path: '/member/BankPayment',
        exact: true,
        auth: true,
        component: MemberPaymentBank,
    },
    {
        path: '/member/BankPayment/edit/:id',
        exact: true,
        auth: true,
        component: MemberPaymentBankEdit,
    },
    {
        path: '/member/ElectronicWalletAuth',
        exact: true,
        auth: true,
        component: MemberElectronicWalletAuth,
    },
    {
        path: '/member/BidUrl',
        exact: true,
        auth: true,
        component: MemberBidUrl,
    },
    {
        path: '/member/BidUrl/edit/:id',
        exact: true,
        auth: true,
        component: MemberBidUrlEdit,
    },
    {
        path: '/member/OrgChartUrl',
        exact: true,
        auth: true,
        component: MemberOrgChartUrl,
    },
    {
        path: '/member/OrgChartUrl/edit/:id',
        exact: true,
        auth: true,
        component: MemberOrgChartUrlEdit,
    },
    {
        path: '/member/TravelPointUrl',
        exact: true,
        auth: true,
        component: MemberTravelPointUrl,
    },
    {
        path: '/member/TravelPointUrl/edit/:id',
        exact: true,
        auth: true,
        component: MemberTravelPointUrlEdit,
    },
    {
        path: '/finance/Assets',
        exact: true,
        auth: true,
        component: FinanceAssets,
    },
    {
        path: '/finance/Transfer',
        exact: true,
        auth: true,
        component: FinanceTransfer,
    },
    {
        path: '/finance/MemberBonus',
        exact: true,
        auth: true,
        component: FinanceMemberBonus,
    },
    {
        path: '/finance/Exchange',
        exact: true,
        auth: true,
        component: FinanceExchange,
    },
    {
        path: '/bid/List',
        exact: true,
        auth: true,
        component: BidList,
    },
    {
        path: '/bid/SuccessfulBidder',
        exact: true,
        auth: true,
        component: BidSuccessfulBidder,
    },
    {
        path: '/bid/Config',
        exact: true,
        auth: true,
        component: BidConfig,
    },
    {
        path: '/application/News',
        exact: true,
        auth: true,
        component: ApplicationNews,
    },
    {
        path: '/application/News/create',
        exact: true,
        auth: true,
        component: ApplicationNewsCreate,
    },
    {
        path: '/application/News/edit/:id',
        exact: true,
        auth: true,
        component: ApplicationNewsEdit,
    },
    {
        path: '/application/Notification',
        exact: true,
        auth: true,
        component: ApplicationNotification,
    },
    {
        path: '/application/Notification/Create',
        exact: true,
        auth: true,
        component: ApplicationNotificationCreate,
    },
    {
        path: '/application/Notification/edit/:id',
        exact: true,
        auth: true,
        component: ApplicationNotificationEdit,
    },
    {
        path: '/application/Banner',
        exact: true,
        auth: true,
        component: ApplicationBanner,
    },
    {
        path: '/application/Banner/create',
        exact: true,
        auth: true,
        component: ApplicationBannerCreate,
    },
    {
        path: '/application/Banner/edit/:id',
        exact: true,
        auth: true,
        component: ApplicationBannerEdit,
    },
    {
        path: '/application/UserGuide',
        exact: true,
        auth: true,
        component: ApplicationUserGuide,
    },
    {
        path: '/application/Contract',
        exact: true,
        auth: true,
        component: ApplicationContract,
    },
    {
        path: '/application/AboutUs',
        exact: true,
        auth: true,
        component: ApplicationAboutUs,
    },
    {
        path: '/application/CompanyAccount',
        exact: true,
        auth: true,
        component: ApplicationCompanyAccount,
    },
    {
        path: '/application/Sms',
        exact: true,
        auth: true,
        component: ApplicationSms,
    },
];

export default routes;