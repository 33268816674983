import React , {useState, useEffect} from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import Font from '@ckeditor/ckeditor5-font/src/font';

const Editor = ({ data, callback }) => {
    return (
        <div className="ck-editor">
            <div id="toolbar-container"></div>
            <CKEditor
                editor={ DecoupledEditor }
                data={data}
                onInit={ editor => {
                    const toolbarContainer = document.querySelector( '#toolbar-container' );
                        toolbarContainer.appendChild( editor.ui.view.toolbar.element );

                    window.editor = editor;
                        // You can store the "editor" and use when it is needed.
                        // console.log( 'Editor is ready to use!', editor );
                } }
                onChange={ ( event, editor ) => {
                        const newData = editor.getData();
                        callback(newData);
                } }
                config={{
                    ckfinder: {
                        // Upload the images to the server using the CKFinder QuickUpload command
                        // You have to change this address to your server that has the ckfinder php connector
                        uploadUrl: '/admin/api/upload'
                    },
                    // plugins: [ Font ],
                    // toolbar: [
                    //     'fontSize',
                    // ]
                }}
                // fontSize={{
                //     options: [
                //         12,
                //         13,
                //         14,
                //         15,
                //         16,
                //         17,
                //         18,
                //         19,
                //         20,
                //         21,
                //         22,
                //         23,
                //         24,
                //         30,
                //         36,
                //         48,
                //         72,
                //     ]
                // }}
            />
        </div>
    )
}

export default Editor;