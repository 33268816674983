import React from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';

const PopoverBox = (props) => {
    const { targetItem, className, arrow, target, title, toggle, callback, children } = props;

    return (<>
        { targetItem }
        <Popover
            trigger="legacy"
            className={`${arrow === false ? "no-arrow" : ""} ${className}`}
            placement="bottom"
            isOpen={toggle}
            target={target}
            toggle={() => callback(false)}
        >
            <PopoverHeader>{ title }</PopoverHeader>
            <PopoverBody>{ children }</PopoverBody>
        </Popover>
    </>)
}

export default PopoverBox;