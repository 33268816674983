import React, { useState } from 'react';
import useForm from "react-hook-form";
import Upload from "../../../components/Upload";
import { FormGroup, InputGroup } from '../../../components/FormGroup';

export default function Create() {
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架">
                        <label className="switch" >
                            <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="排序" required>
                        <input
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入排序"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="上傳圖片" desc="請上傳最小寬 768px*307px JPEG/PNG 圖檔" required>
                        <Upload
                            id="banner"
                            maxWidth="auto"
                            maxHeight="800px"
                            name="banner"
                            valid={{width: 768, height: 307, type: ["image/jpeg", "image/png"]}}
                            register={register}
                            clearCallback={() => setValue("banner", "")}
                        />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}