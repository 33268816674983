import React, { useState } from 'react';
import useForm from "react-hook-form";
import Div100vh from 'react-div-100vh';
import TextField from '@material-ui/core/TextField';
import AuthService from '../../../services';
import * as action from '../../../store/actions';

export default function Page(props) {
    const { setSnack, setLoader } = props;
    const { register, handleSubmit, formState } = useForm({ mode: "onChange" });
    const [ buttonSubmit, setButtonSubmit ] = useState(false);
    const [ passwordShow, setPasswordShow ] = useState(false);
    
	const onSubmit = data => {
        if(data.password.length < 6){
            setSnack({open: true, text: "密碼最少為6位"});
            return;
        }
        setLoader(true);
        AuthService.login(data).then(res => {
            setLoader(false);
            props.dispatch(action.authLogin(res));
            setSnack({open: true, text: "登入成功"});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }
    
    return (
        <Div100vh>
            <div className="login-page">
                <div className="logo"></div>
                <div className="title">APE會員繳費後台管理</div>
                <div className="login-container">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="input-group mb-2">
                            <TextField
                                name="username"
                                className="w-100"
                                label="帳號"
                                variant="outlined"
                                autoComplete="new-username"
                                spellCheck="false"
                                inputRef={register({ required: true })}
                            />
                        </div>
                        <div className="input-group mb-6">
                            <TextField
                                name="password"
                                className="w-100"
                                type={passwordShow ? "text" : "password"}
                                label="密碼"
                                variant="outlined"
                                autoComplete="new-password"
                                spellCheck="false"
                                inputRef={register({ required: true })}
                            />
                            <div className="input-text">
                                <button type="button" className="view-pass-btn" onClick={() => setPasswordShow(!passwordShow)}>
                                    {passwordShow ? (<i className="far fa-eye-slash"></i>) : (<i className="far fa-eye"></i>)}
                                </button>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="login-btn"
                            disabled={ !formState.dirty || (formState.dirty && !formState.isValid) ? true : buttonSubmit }
                        >
                            登入
                        </button>
                    </form>
                </div>
                {/* <div className="login-box">
                    <div className="card">
                        <div className="card-body login-card-body">
                            <div className="login-logo">
                                <img src={require('../../../../images/logo/logo_white2.png').default} alt="" style={{width: '100px'}} />
                            </div>
                            <p className="login-box-msg">管理者後台</p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="input-group mb-3">
                                    <input 
                                        type="text"
                                        name="username"
                                        className="form-control admin-input"
                                        placeholder="帳號"
                                        ref={register({ required: true })}
                                        autoComplete="new-username"
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control admin-input"
                                        placeholder="密碼"
                                        ref={register({ required: true })}
                                        autoComplete="new-password"
                                    />
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-warning btn-block font-weight-bold"
                                        disabled={buttonSubmit}
                                    >
                                            登入
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div> */}
            </div>
        </Div100vh>
    )
}
