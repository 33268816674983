import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';
import Editor from '../../../components/Editor';

const data2 = [
    {
        id: 1,
        type: "一般",
        title: "title1",
        content: "content1",
        status: "1",
        top: "1",
        created_at: "2021/06/08 15:10:31",
    },
    {
        id: 2,
        type: "活動",
        title: "title2",
        content: "content2",
        status: "0",
        top: "1",
        created_at: "2021/06/07 15:10:31",
    },
    {
        id: 3,
        type: "系統",
        title: "title3",
        content: "content3",
        status: "1",
        top: "0",
        created_at: "2021/06/06 15:10:31",
    },
    {
        id: 4,
        type: "其它",
        title: "title4",
        content: "content4",
        status: "0",
        top: "0",
        created_at: "2021/06/05 15:10:31",
    },
]

export default function Edit() {
    let mounted = true;
    const { id } = useParams();
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);
    const [ topToggle, setTopToggle ] = useState(false);
    const [ newsType, setNewsType ] = useState("一般");
    const [ data, setData ] = useState({});
    const [ editorData, setEditorData ] = useState("");

    const fetchData = useCallback(async() => {
        let result = await data2;
        if(mounted) {
            let resultData = result.find(e => e.id == id);
            setData(resultData);
            setShowToggle(resultData.status === "1" ? true : false);
            setTopToggle(resultData.top === "1" ? true : false);
            setNewsType(resultData.type);
            setEditorData(resultData.content);
        }
    }, [mounted])

    useEffect(() => {
        fetchData();
        return () => {
            mounted = false;
        }
    }, [fetchData])

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架狀態">
                        <div className={`mr-2 ${showToggle ? "text-success" : "text-danger"}`}>{showToggle ? "上架" : "下架"}</div>
                        <label className="switch" >
                            <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="置頂">
                        <label className="switch" >
                            <input type="checkbox" checked={topToggle} onChange={() => setTopToggle(!topToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="選擇分類" required>
                        <div className="radio-container">
                            <div className="radio-box">
                                <input type="radio" value="一般" checked={newsType === "一般"} name="news_type" id="news_type1" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type1">一般</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="活動" checked={newsType === "活動"} name="news_type" id="news_type2" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type2">活動</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="系統" checked={newsType === "系統"} name="news_type" id="news_type3" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type3">系統</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="其它" checked={newsType === "其它"} name="news_type" id="news_type4" onChange={(e) => setNewsType(e.target.value)} />
                                <label htmlFor="news_type4">其它</label>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup label="標題" required>
                        <input
                            name="name"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入標題"
                            defaultValue={data.title}
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="內容" required>
                        <Editor data={editorData} callback={(data) => setEditorData(data)} />
                    </FormGroup>
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}