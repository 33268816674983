import React, { useState } from 'react';
import useForm from "react-hook-form";
import { FormGroup, InputGroup } from '../../../components/FormGroup';

export default function Create() {
    const { register, handleSubmit, getValues, reset, setValue } = useForm();
    const [ showToggle, setShowToggle ] = useState(true);
    const [ notificationType, setNotificationType ] = useState("不限定");

    const onSubmit = data => {
        console.log(data);
    }

    return (
        <div className="tools-tags-create-page">
            <form className="common-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">
                    <FormGroup label="上下架">
                        <label className="switch" >
                            <input type="checkbox" checked={showToggle} onChange={() => setShowToggle(!showToggle)}/>
                            <span className="slider round"></span>
                        </label>
                    </FormGroup>
                    <FormGroup label="推播標題" required>
                        <input
                            name="name"
                            type="text"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入推播標題"
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup label="推播內容" required>
                        <textarea
                            rows="6"
                            spellCheck="false"
                            autoComplete="off"
                            placeholder="請輸入推播內容"
                        />
                    </FormGroup>
                    <FormGroup label="推播對象" required>
                        <div className="radio-container">
                            <div className="radio-box">
                                <input type="radio" value="不限定" checked={notificationType === "不限定"} name="notification_type" id="notification_type1" onChange={(e) => setNotificationType(e.target.value)} />
                                <label htmlFor="notification_type1">不限定</label>
                            </div>
                            <div className="radio-box">
                                <input type="radio" value="限定" checked={notificationType === "限定"} name="notification_type" id="notification_type2" onChange={(e) => setNotificationType(e.target.value)} />
                                <label htmlFor="notification_type2">限定</label>
                            </div>
                        </div>
                    </FormGroup>
                    {
                        notificationType === "限定" && (<>
                            <FormGroup label="發送帳號" required>
                                <input
                                    name="name"
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="請輸入發送帳號"
                                    ref={register}
                                />
                            </FormGroup>
                            <FormGroup label="發送姓名" desc="無須手動輸入，正確填入上方發送帳號即會自動產生">
                                <input
                                    type="text"
                                    spellCheck="false"
                                    autoComplete="off"
                                    placeholder="( 偵測不到姓名，請重新輸入帳號 )"
                                    readOnly
                                />
                            </FormGroup>
                        </>)
                    }
                </div>
                <div className="text-right">
                    <button type="submit" className="submit-btn">送出</button>
                </div>
            </form>
        </div>
    )
}