import React from 'react';
import { motion } from "framer-motion";

const transition = { duration: 0.35, ease: 'easeInOut' };

const thumbnailVariants = {
    initial: { opacity: 0, transition },
    enter: { opacity: 1, transition },
    exit: { opacity: 0, transition }
};

export default function Fade({ children, transition }) {

    if(!transition) {
        return (
            <section className="content mb-3">
                { children }
            </section>
        );
    }

    return (
        <motion.section initial="initial" animate="enter" exit="exit" className="content mb-3" variants={thumbnailVariants}>
            { children }
        </motion.section>
    )
}
